@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  font-family: $font-family;
  max-width: 1080px;
  margin: 40px auto;
  background-color: #FEF4ED;
  border-radius: 8px;

  @include respond(tablet) {
    flex-direction: column-reverse;
    padding: 25px 0px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 0px 24px 40px;
    row-gap: 18px;
    align-items: flex-start;

    @include respond(tablet) {
      padding-right: 40px;
    }

    .contentHeadingWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      max-width: 502px;

      h3 {
        color: #2F3136;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
      }

      p {
        color: #2F3136;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .exploreNowBtn {
      display: inline-flex;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid #CDCECE;
      padding: 9px 16px;
      border-radius: 8px;
      color: #423DCF;
      column-gap: 12px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        background-color: darken($color: #FFFFFF, $amount: 5);
      }
    }
  }

  .illustrationWrapper {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}